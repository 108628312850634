import { DatePickerMode, DateRange } from "~/types/general";

export const useSetDateRange = (
  datePickerMode: DatePickerMode,
  date: any,
  selectedDateRange: DateRange
): {
  newDateRange: DateRange;
} => {
  const monthDaysQuantity = new Date(date.year, date.month + 1, 0).getDate();
  switch (datePickerMode) {
    case DatePickerMode.ALL:
    case DatePickerMode.QUARTER:
    case DatePickerMode.WEEK:
      selectedDateRange.start = `${useDateFormatLib(
        date[0],
        "yyyy-mm-dd"
      )} 00:00:00`;
      selectedDateRange.end = `${useDateFormatLib(
        date[1],
        "yyyy-mm-dd"
      )} 23:59:59`;
      break;
    case DatePickerMode.YEAR:
      selectedDateRange.start = `${date}-01-01 00:00:00`;
      selectedDateRange.end = `${date}-12-31 23:59:59`;
      break;
    case DatePickerMode.MONTH:
      selectedDateRange.start = `${date.year}-${
        date.month + 1 < 10 ? `0${date.month + 1}` : date.month + 1
      }-01 00:00:00`;
      selectedDateRange.end = `${date.year}-${
        date.month + 1 < 10 ? `0${date.month + 1}` : date.month + 1
      }-${monthDaysQuantity} 23:59:59`;
      break;
    case DatePickerMode.DAY:
      selectedDateRange.start = `${useDateFormatLib(
        date,
        "yyyy-mm-dd"
      )} 00:00:00`;
      selectedDateRange.end = `${useDateFormatLib(
        date,
        "yyyy-mm-dd"
      )} 23:59:59`;
  }

  return {
    newDateRange: selectedDateRange,
  };
};
